
import Routes from 'routes';
import ThemeCustomization from 'themes';
import {Amplify} from 'aws-amplify';

import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
// import Customization from 'components/Customization';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import FirebaseComponent from 'components/FirebaseComponent';
import config from 'aws-config';
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'; 
import { TableContextProvider  } from 'contexts/TableContext'; 
Amplify.configure({
  ...config
});
const App = () => {

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
            <FirebaseComponent></FirebaseComponent>
              <>
                <Notistack>
                  <TableContextProvider>
                   <Routes />
                  </TableContextProvider>
                  {/* <Customization /> */}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
