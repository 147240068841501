/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { dashboardConstants } from '../_constants';

const initialState = {
  records: [],
  barData:[],
  loading: false,
  barDataLoading: false,
  error: false, 
  success: false, 
  message: null,
  pageSize: 5,
  page: 0,
  total: null, 
  filters: null
};

export default function Reducer(state = initialState, props) {
  const { type, records,  error, message, page, total } = props;

  return produce(state, (draft) => {
    switch (type) { 
      case dashboardConstants.LOAD_DASHBOARD_RECORDS:
        draft.loading = true;
        draft.error = false; 
        draft.success = false;
        draft.message = '';
        break;
      case dashboardConstants.LOAD_DASHBOARD_RECORDS_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        break;  
      case dashboardConstants.LOAD_DASHBOARD_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
        case dashboardConstants.LOAD_BAR_CHART_RECORDS:
          draft.barDataLoading = true;
          draft.error = false; 
          draft.success = false;
          draft.message = '';
          break;
        case dashboardConstants.LOAD_BAR_CHART_RECORDS_SUCCESS:
          draft.barData = records;
          draft.barDataLoading = false;
          draft.error = false;
          draft.success = true;
          draft.message = '';
          draft.total = total;
          draft.page = page;
          break;  
        case dashboardConstants.LOAD_BAR_CHART_RECORDS_ERROR:
          draft.barDataLoading = false;
          draft.error = error;
          draft.success = false;
          draft.message = message;
          break;
      default:
        break;
    }
  });
}
