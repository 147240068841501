/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/dashboard`;

const LOAD_DASHBOARD_RECORDS = `${url}/LOAD_DASHBOARD_RECORDS`;
const LOAD_DASHBOARD_RECORDS_SUCCESS = `${url}/LOAD_DASHBOARD_RECORDS_SUCCESS`;
const LOAD_DASHBOARD_RECORDS_ERROR = `${url}/LOAD_DASHBOARD_RECORDS_ERROR`;
const LOAD_BAR_CHART_RECORDS = `${url}/LOAD_BAR_CHART_RECORDS`;
const LOAD_BAR_CHART_RECORDS_SUCCESS = `${url}/LOAD_BAR_CHART_RECORDS_SUCCESS`;
const LOAD_BAR_CHART_RECORDS_ERROR = `${url}/LOAD_BAR_CHART_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORDS`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const CLEAR = `${url}/CLEAR`;


export const dashboardConstants = {
  LOAD_DASHBOARD_RECORDS,
  LOAD_DASHBOARD_RECORDS_SUCCESS,
  LOAD_DASHBOARD_RECORDS_ERROR,
  LOAD_BAR_CHART_RECORDS,
  LOAD_BAR_CHART_RECORDS_ERROR,
  LOAD_BAR_CHART_RECORDS_SUCCESS,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CLEAR

};
