import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import * as store from 'store';
import { dialogActions} from 'store/actions';


const Dashboard = Loadable(lazy(() => import('pages/apps/dashboard')));
const Upload = Loadable(lazy(() => import('pages/apps/upload')));
const History = Loadable(lazy(() => import('pages/apps/history/receipts/receipt')));
const CustomizedItemTypes= Loadable(lazy(() => import('pages/apps/settings/customizedItemTypes')));
const ManageProjects=Loadable(lazy(() => import('pages/apps/settings/manageProjects')));
const ViewHistory = Loadable(lazy(() => import('pages/apps/history/receipts/view')));
const EditHistory = Loadable(lazy(() => import('pages/apps/history/receipts/Edit')));
const Ingredients = Loadable(lazy(() => import('pages/apps/history/ingredients/ingredients')));
const ViewIngredients = Loadable(lazy(() => import('pages/apps/history/ingredients/view')));
const Nutritions = Loadable(lazy(() => import('pages/apps/history/nutritions/nutritions')));
const ViewNutritions = Loadable(lazy(() => import('pages/apps/history/nutritions/view')));
const ConfigReports = Loadable(lazy(() => import('pages/apps/settings/configReports')));
const Plans= Loadable(lazy(() => import('pages/apps/settings/plans')));


const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),

      children: [
        {
          path: 'upload',
          element: <Upload dialogActions={dialogActions} {...store} />
        },
        {
          path: 'dashboard',
          element: <Dashboard dialogActions={dialogActions} {...store} />
        },
        {
          path: 'history',
          children: [
            {
              path: 'receipt/edit/:id',
              element: (
                <EditHistory dialogActions={dialogActions} {...store} />
              )
            },
            {
              path: 'receipt',
              element: <History dialogActions={dialogActions} {...store} />
            },
            {
              path: 'receipt/view',
              element: <ViewHistory dialogActions={dialogActions} {...store} />
            },
            {
              path: 'ingredients',
              element: <Ingredients dialogActions={dialogActions} {...store} /> 
            },
            {
              path: 'ingredients/view/:id',
              element: <ViewIngredients dialogActions={dialogActions} {...store} />
            },
            {
              path: 'nutritions',
              element: <Nutritions dialogActions={dialogActions} {...store} /> 
            },
            {
              path: 'nutritions/view/:id',
              element: <ViewNutritions dialogActions={dialogActions} {...store} />
            }
           
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'customizeditemtypes',
              element: <CustomizedItemTypes dialogActions={dialogActions} {...store} />
            },
            {
              path: 'config-reports',
              element: (
                <ConfigReports dialogActions={dialogActions} {...store} />
              )   
            },
            {
              path: 'manage-projects',
              element: (
                <ManageProjects dialogActions={dialogActions} {...store} />
              )   
            },
            {
              path: 'plans',
              element: (
                <Plans dialogActions={dialogActions} {...store} />
              )   
            }
          ]
         
        },
      ]
    }
  ]
};

export default MainRoutes;
