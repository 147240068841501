import { historyService } from 'store/services';
import { historyConstants } from 'store/_constants';
import { snackbarActions, handleSubscriptionStatus  } from 'helpers/tools';
import { loaderActions } from './loader.actions';
import { dialogActions } from './dialog.actions';

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    historyService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: historyConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: historyConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: historyConstants.LOAD_RECORDS_ERROR, error, message };
  }
}
function searchRecord(params) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await historyService.searchRecord(params);
      const storeTypes = response.store_type; 
      dispatch(success(storeTypes));
    } catch (error) {
      console.error('Action Error:', error);
      dispatch(failure(true, error.message));
    }
  };

  function request() {
    return { type: historyConstants.SEARCH_RECORDS };
  }

  function success(storeTypes) {
    return { type: historyConstants.SEARCH_RECORDS_SUCCESS, storeTypes };
  }

  function failure(error, message) {
    return { type: historyConstants.SEARCH_RECORDS_ERROR, error, message };
  }
}

function itemTypesOptions() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await historyService.itemTypes();
      const itemTypes = response.map((item) => ({
        label: item.item_type,
        value: item.id,
      }));
      dispatch(success(itemTypes));
    } catch (error) {
      console.error('Action Error:', error);
      dispatch(failure(true, error.message));
    }
  };

  function request() {
    return { type: historyConstants.ITEM_TYPE_RECORDS };
  }

  function success(itemTypes) {
    return { type: historyConstants.ITEM_TYPE_RECORDS_SUCCESS, itemTypes };
  }

  function failure(error, message) {
    return { type: historyConstants.ITEM_TYPE_RECORDS_ERROR, error, message };
  }
}
  
function viewRecords({ imageId, category_type,project_id}) {
  return (dispatch) => {
    dispatch(request());
    historyService.viewRecords({ imageId, category_type,project_id }).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };

  function request() {
    return { type: historyConstants.VIEW_RECORDS };
  }

  function success(records) {
    return { type: historyConstants.VIEW_RECORDS_SUCCESS, records };
  }

  function failure(error, message) {
    return { type: historyConstants.VIEW_RECORDS_ERROR, error, message };
  }
}

function deleteRecord({imageId,category_type,project_id}, navigate) {
  return (dispatch) => {
    dispatch(request());
    historyService.deleteRecord({imageId,category_type,project_id}).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot') || message.includes("can't")) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(imageId, message, error));
          dispatch(dialogActions.success(false));
         
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error.toString()));
      }
    );
  };
  function request() {
    return { type: historyConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: historyConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: historyConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function updateRecord(payload, queryParams, navigate) {
  return (dispatch) => {
    dispatch(request());
    historyService.updateRecord(payload, queryParams).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response));
          dispatch(loaderActions.end());
          navigate(-1)
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
      )}

  function request() {
    return { type: historyConstants.UPDATE_RECORD };
  }
  function success(record, message, error) {
    return { type: historyConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: historyConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function ratingRecords(payload) {
  return (dispatch) => {
    dispatch(request());
    historyService.ratingRecords(payload).then(
      (res) => {
        const { response, error, message } = res;
       
        if (error) {
          dispatch(failure(true, message));
          handleSubscriptionStatus(message);
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response));
          dispatch(loaderActions.end());
          
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
      )}

  function request() {
      return { type: historyConstants.RATING_RECORDS };
    }
  
    function success(rating) {
      return { type: historyConstants.RATING_RECORDS_SUCCESS, rating };
    }
  
    function failure(error, message) {
      return { type: historyConstants.RATING_RECORDS_ERROR, error, message };
    }
}

export const historyActions = {
  loadRecords,
  searchRecord,
  viewRecords,
  deleteRecord,
  updateRecord,
  itemTypesOptions,
  ratingRecords
};
