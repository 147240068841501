const url = `app/settings`;

const GET_REWARD_RECORD=`${url}/GET_REWARD_RECORD`;
const GET_REWARD_RECORD_SUCCESS=`${url}/GET_REWARD_RECORD_SUCCESS`;
const GET_REWARD_RECORD_ERROR=`${url}/GET_REWARD_RECORD_ERROR`;
 
export const rewardConstants = {
    GET_REWARD_RECORD_ERROR,
    GET_REWARD_RECORD_SUCCESS,
    GET_REWARD_RECORD,
}