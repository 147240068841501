import produce from 'immer';

import { settingsConstants } from '../_constants';

const initialState = {
  projectList: [],
  records: [],
  itemTypes: [],
  projectNames: [],
  loading: false,
  error: false,
  success: false,
  message: null,
  pageSize: 5,
  page: 0,
  total: null,
 
};

export default function Reducer(state = initialState, props) {
  const { type, response, error, message, records, total, page } = props;
 

  return produce(state, (draft) => {
    switch (type) {
      case settingsConstants.ITEM_TYPE_RECORDS:
        draft.error = false;
        draft.loading = true;
        draft.success = false;
        draft.message = '';
        break;
      case settingsConstants.ITEM_TYPE_RECORDS_SUCCESS:
        draft.itemTypes = response;
        draft.loading = false;
        draft.error = false;
        break;
      case settingsConstants.ITEM_TYPE_RECORDS_ERROR:
         draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;

      case settingsConstants.ADD_ITEM_TYPE:
        draft.loading = true;
        draft.success = false;
        draft.error = false;
        draft.message = null;
        break;
      case settingsConstants.ADD_ITEM_TYPE_SUCCESS:
         draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case settingsConstants. ADD_ITEM_TYPE_ERROR:
        draft.loading = false;
        draft.success = false;
        draft.error = true;
        draft.message = message;
        break;

        case settingsConstants.UPDATE_ITEM_TYPE:
        draft.loading = true;
        draft.success = false;
        draft.error = false;
        draft.message = null;
        break;
      case settingsConstants.UPDATE_ITEM_TYPE_SUCCESS:
        draft.loading = false;
        draft.success = true;
        draft.error = false;
        draft.message = message;
        break;
      case settingsConstants. UPDATE_ITEM_TYPE_ERROR:
        draft.loading = false;
        draft.success = false;
        draft.error = true;
        draft.message = message;
        break;
        case settingsConstants.GET_DOWNLOAD_HISTORY:
          draft.loading = true;
          draft.success = false;
          draft.error = false;
          draft.message = null;
          break;
        case settingsConstants.GET_DOWNLOAD_HISTORY_SUCCESS:
          draft.records = records;
          draft.loading = false;
          draft.success = true;
          draft.error = false;
          draft.message = null;
          draft.total = total;
          draft.page = page;
          break;
        case settingsConstants.GET_DOWNLOAD_HISTORY_ERROR:
          draft.loading = false;
          draft.success = false;
          draft.error = error;
          draft.message = message;
          break;
        case settingsConstants.DOWNLOAD_RECORDS:
          draft.loading = true;
          draft.success = false;
          draft.error = false;
          draft.message = null;
          break;
        case settingsConstants.DOWNLOAD_RECORDS_SUCCESS:
          draft.loading = false;
          draft.success = true;
          draft.error = false;
          draft.message = null;
          break;
        case settingsConstants.DOWNLOAD_RECORDS_ERROR:
          draft.loading = false;
          draft.success = false;
          draft.error = error;
          draft.message = message;
          break;
          case settingsConstants.ADD_ITEM_TYPE:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.message = null;
            break;
          case settingsConstants.ADD_ITEM_TYPE_SUCCESS:
             draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.message = message;
            break;
          case settingsConstants. ADD_ITEM_TYPE_ERROR:
            draft.loading = false;
            draft.success = false;
            draft.error = true;
            draft.message = message;
            break;
            case settingsConstants.ADD_PROJECT_NAME:
              draft.error = false;
              draft.loading = true;
              draft.success = false;
              draft.message = '';
              break;
            case settingsConstants.ADD_PROJECT_NAME_SUCCESS:
              draft.success = true;
              draft.loading = false;
              draft.error = false;
              break;
            case settingsConstants. ADD_PROJECT_NAME_ERROR:
              draft.loading = false;
              draft.error = error;
              draft.success = false;
              draft.message = message;
              break;
              case settingsConstants.GET_PROJECT_NAME:
                draft.error = false;
                draft.loading = true;
                draft.success = false;
                draft.message = '';
                draft.projectNames = [];
                break;
              case settingsConstants.GET_PROJECT_NAME_SUCCESS:
                draft.projectNames = response;
                draft.loading = false;
                draft.error = false;
                break;
              case settingsConstants.GET_PROJECT_NAME_ERROR:
                draft.loading = false;
                draft.error = error;
                draft.success = false;
                draft.message = message;
                break;
                case settingsConstants.DELETE_PROJECT_NAME:
                  draft.loading = true;
                  draft.updateError = null;
                  draft.success = false;
                  draft.error = false;
                  draft.updateSuccess = false;
                  draft.message = null;
                  break;
                case settingsConstants.DELETE_PROJECT_NAME_SUCCESS:
                  draft.loading = false;
                  draft.error = false;
                  draft.success = false;
                  draft.message = message;
                  draft.deleteSuccess = true;
                 
                  break;
                case settingsConstants.DELETE_PROJECT_NAME_ERROR:
                  draft.loading = false;
                  draft.updateError = error;
                  draft.success = false;
                  draft.error = true;
                  draft.deleteSuccess = false;
                  draft.message = message;
                  break;
                case settingsConstants.UPDATE_PROJECT_NAME:
                  draft.loading = true;
                  draft.updateError = null;
                  draft.success = false;
                  draft.error = false;
                  draft.updateSuccess = false;
                  draft.message = null;
                  break;
                case settingsConstants.UPDATE_PROJECT_NAME_SUCCESS:
                  draft.loading = false;
                  draft.updateError = null;
                  draft.success = true;
                  draft.error = false;
                  draft.updateSuccess = true;
                  draft.message = message;
                  break;
                case settingsConstants.UPDATE_PROJECT_NAME_ERROR:
                  draft.loading = false;
                  draft.updateError = error;
                  draft.success = false;
                  draft.error = true;
                  draft.updateSuccess = false;
                  draft.message = message;
                  break;           
                case settingsConstants.GET_PROJECT_LIST:
                  draft.error = false;
                  draft.loading = true;
                  draft.success = false;
                  draft.message = '';
                  break;
                case settingsConstants.GET_PROJECT_LIST_SUCCESS:
                  draft.projectList = response;
                  draft.loading = false;
                  draft.error = false;
                  break;
                case settingsConstants.GET_PROJECT_LIST_ERROR:
                   draft.loading = false;
                  draft.error = error;
                  draft.success = false;
                  draft.message = message;
                  break;
                  
                
      default:
        break;
    }
  });
}
