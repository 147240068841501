import { getDefaultParams, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';
function loadRecords(params) {
    const queryParams = buildQuery(params);
    return API.get('leardon-service', '/get-history', getDefaultParams(null, { queryStringParameters: queryParams }))
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }
  function searchRecord(params) {
    return API.get('leardon-service', '/get-history', getDefaultParams(null, { queryStringParameters: params }))
      .then((res) => {
        return res.response; 
      })
      .catch((error) => {
        console.error('API Error:', error);
        return Promise.reject(error);
      });
  }
  function itemTypes() {
    return API.get('leardon-service', '/get-item-types', getDefaultParams(null))
      .then((res) => {
        return res.response; 
      })
      .catch((error) => {
        console.error('API Error:', error);
        return Promise.reject(error);
      });
  }
  function viewRecords({ imageId, category_type,project_id }) {
    return API.get('leardon-service', '/view-product', getDefaultParams(null, {
      queryStringParameters: { imageId, category_type,project_id }
    }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
  }

  function deleteRecord(imageId, categoryType, project_id) {
    return API.del('leardon-service', '/delete-history', getDefaultParams(null, { queryStringParameters: imageId,}))
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }  

  function updateRecord(payload, queryParams) {
    return API.put('leardon-service', '/update-history', getDefaultParams({body: payload}, { queryStringParameters: queryParams }))
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }
  function ratingRecords(payload) {
    return API.put('leardon-service', '/view-product/more-details', getDefaultParams({body: payload}))
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }
  export const historyService = {
    loadRecords,
    viewRecords,
    searchRecord,
    deleteRecord,
    updateRecord,
    itemTypes,
    ratingRecords
  }