
import { getDefaultParams } from 'helpers/tools';
import { API } from 'aws-amplify';

function projectList() {
    return API.get('leardon-service','/create-project-management/get-project', getDefaultParams(null))
      .then((res) => {
        return res.response;
      })
      .catch((error) => {
        console.error('API Error:', error);
        return Promise.reject(error);
      });
  }

  
  export const projectService={
    projectList,
  }