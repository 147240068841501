import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, MenuItem, Select, FormControl, Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Add } from 'iconsax-react';
import AppBarStyled from './AppBarStyled';
import bellIcon from 'assets/icons/bell.icon.svg';
import SvgIcon from 'components/SvgElement';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'components/@extended/IconButton';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';
import { projectActions } from 'store/actions';
import { HambergerMenu } from 'iconsax-react';
import { LAYOUT_CONST } from 'types/config';
import useAuth from 'hooks/useAuth';
 
 
const Header = ({ open, handleDrawerToggle, isLoggedIn }) => {
  const theme = useTheme();
  const { updateProjectId } = useAuth();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();
  const navigate = useNavigate();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
 
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';
  const dispatch = useDispatch();
 
  const { projectList } = useSelector((state) => state?.project);
  const [projectListOptions, setProjectListOptions] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState('');
 
  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        await dispatch(projectActions.getProjectList());
      } catch (error) {
        console.error('Error fetching project list:', error);
      }
    };
 
    fetchProjectList();
  }, [dispatch]);
 
  useEffect(() => {
    if (projectList && projectList.length > 0) {
      const options = projectList.map((project) => ({
        id: project.id,
        name: project.project_name,
      }));
      setProjectListOptions(options);
 
      const savedProjectName = localStorage.getItem('selectedProjectName') || 'Primary';
 
      let selected = options.find((option) => option.name === savedProjectName);
      if (!selected) {
        selected = options[0];
      }
 
      setSelectedProjectName(selected.name);
      localStorage.setItem('selectedProjectName', selected.name);
      localStorage.setItem('selectedProjectId', selected.id);
 
      updateProjectId(selected.id);
    }
  }, [projectList, updateProjectId]);
 
  const handleProjectChange = (event) => {
    const newValue = event.target.value;
    const newSelectedProject = projectListOptions.find((project) => project.name === newValue);
 
    if (newSelectedProject) {
      setSelectedProjectName(newSelectedProject.name);
      localStorage.setItem('selectedProjectId', newSelectedProject.id);
      localStorage.setItem('selectedProjectName', newSelectedProject.name);
 
      updateProjectId(newSelectedProject.id);
    }
  };
  const handleItemTypeAdd = () => {
    navigate('settings/manage-projects');
  };
 
  const mainHeader = (
    <>
    <Toolbar sx={{ px: { xs: 2, sm: 4.5, lg: 8 }, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>
        {downLG && !isHorizontal ? (
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            variant="light"
            size="large"
            sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 }, p: 1 }}
          >
            <HambergerMenu />
          </IconButton>
        ) : null}
      </div>
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <FormControl size="small" sx={{ width: '160px', marginRight: '20px' }}>
          <Select
            value={selectedProjectName}
            onChange={handleProjectChange}
            displayEmpty
            renderValue={(selected) => {
              const selectedProject = projectListOptions.find((project) => project.name === selected);
              return selectedProject ? selectedProject.name : 'Current Project';
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2e5cb8',
                boxShadow: '0 0 5px 2px rgb(92, 133, 214)',
              },
              '& .MuiSelect-select': {
                color: '#24478f',
              },
            }}
          >
            {projectListOptions.map((project) => (
              <MenuItem key={project.id} value={project.name}>
                {project.name}
              </MenuItem>
            ))}
            <MenuItem
              onClick={handleItemTypeAdd}
              sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                color: '#3A73AA',
                fontSize: '0.8rem',
                '& .MuiSvgIcon-root': {
                  color: '#3A73AA',
                  height: '2em',
                  marginRight: '10px',
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <Add />
              Add New Project
            </MenuItem>
          </Select>
        </FormControl>
        <IconButton>
          <SvgIcon icon={bellIcon} />
        </IconButton>
      </Box>
    </Toolbar>
    <Divider sx={{ backgroundColor: '#B0B0B0' }} />
    </>
  );
 
  const appBar = {
    position: 'fixed',
    elevation: 0,
    sx: {
      bgcolor: '#ffffff',
      backdropFilter: 'blur(8px)',
      zIndex: 1200,
      width: isHorizontal ? '100%' : open ? `calc(100% - ${DRAWER_WIDTH}px)` : { xs: '100%', lg: `calc(100% - ${MINI_DRAWER_WIDTH}px)` },
    },
  };
 
  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};
 
export default Header;
 
 
