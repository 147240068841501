import { getDefaultParams } from 'helpers/tools';
import { API } from 'aws-amplify';

function rewardRecords() {
  return API.post('leardon-service', '/create-reward', getDefaultParams(null))
    .then((res) => {
      return res.response;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}
export const rewardService = {
    rewardRecords,
};