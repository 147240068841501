import { getDefaultParams, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

function itemTypes() {
  return API.get('leardon-service', '/get-item-types', getDefaultParams(null))
    .then((res) => {
      return res.response;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}
function createItemType(payload) {
  // Define the endpoint and parameters
  return (
    API.post('leardon-service', '/create-item-types', getDefaultParams({ body: payload }))
      // Return the API request with proper parameters
      .then((res) => res)
      .catch((error) => {
        return Promise.reject(error);
      })
  ); // Return the error to be handled by the action
}
function UpdateItemType(payload) {
  // Define the endpoint and parameters
  return (
    API.put('leardon-service', '/create-item-types', getDefaultParams({ body: payload }))
      // Return the API request with proper parameters
      .then((res) => res)
      .catch((error) => {
        return Promise.reject(error);
      })
  ); // Return the error to be handled by the action
}
function getDownloadHistory(params) {
  const queryParams = buildQuery(params);
  return API.get('leardon-service', '/download-history-data/get-download-history', getDefaultParams(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function downloadData(payload, queryParams) {
  return API.post('leardon-service', '/download-history-data', getDefaultParams({ body: payload, queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function addProjectName(payload) {
  return API.post('leardon-service', '/create-project-management', getDefaultParams({ body: payload }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function getProjectName() {
  return API.get('leardon-service', '/create-project-management/get-project', getDefaultParams(null))
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}
function deleteProjectName(payload) {
  return API.del('leardon-service', '/create-project-management/delete-project', getDefaultParams({ body: payload }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function updateProjectName(payload) {
  return API.put('leardon-service', '/create-project-management/update-project', getDefaultParams({ body: payload }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
export const settingsService = {
  createItemType,
  itemTypes,
  UpdateItemType,
  getDownloadHistory,
  downloadData,
  addProjectName,
  getProjectName,
  deleteProjectName,
  updateProjectName
};
