import produce from 'immer';
import { rewardConstants} from '../_constants';

const initialState = {
 
 rewardRecord: [],
  loading: false,
  error: false,
  success: false,
  message: null,

};

export default function rewardReducer(state = initialState, props) {
  const { type, response, error, message,  } = props;
 

  return produce(state, (draft) => {
    switch (type) {
      case rewardConstants.GET_REWARD_RECORD:
        draft.error = false;
        draft.loading = true;
        draft.success = false;
        draft.message = '';
        break;
      case rewardConstants.GET_REWARD_RECORD_SUCCESS:
        draft. rewardRecord = response;
        draft.loading = false;
        draft.error = false;
        break;
      case rewardConstants.GET_REWARD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
       
        default:
   
        break;
}
});
}