/*
 *
 * Session constants
 *
 */
export const sessionConstants = {
    VERIFY_SESSION  :  'app/session/VERIFY_SESSION',
    VERIFY_SESSION_SUCCESS  :  'app/session/VERIFY_SESSION_SUCCESS',
    VERIFY_SESSION_ERROR  :  'app/session/VERIFY_SESSION_ERROR',
    
    LOG_IN  :  'app/session/LOG_IN',
    LOG_IN_SUCCESS  :  'app/session/LOG_IN_SUCCESS',
    LOG_IN_ERROR  :  'app/session/LOG_IN_ERROR',

    LOG_OUT  :  'app/session/LOG_OUT',
    LOG_OUT_SUCCESS  :  'app/session/LOG_OUT_SUCCESS',
    LOG_OUT_ERROR  :  'app/session/LOG_OUT_ERROR',
    LOG_IN_SUCCESS_EXIST : 'app/session/LOG_IN_SUCCESS_EXIST',
    
    FORGOT_PASSWORD  :  'app/session/FORGOT_PASSWORD', 
    FORGOT_PASSWORD_SUCCESS  :  'app/session/FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR  :  'app/session/FORGOT_PASSWORD_ERROR',
    
    VERIFY_PASSWORD_CODE: 'app/session/VERIFY_PASSWORD_CODE',
    VERIFY_PASSWORD_CODE_SUCCESS: 'app/session/VERIFY_PASSWORD_CODE_SUCCESS',
    VERIFY_PASSWORD_CODE_ERROR: 'app/session/VERIFY_PASSWORD_CODE_ERROR',

    NEW_PASSWORD_REQUIRED: 'app/session/NEW_PASSWORD_REQUIRED',
    COMPLETE_NEW_PASSWORD_INIT: 'app/session/COMPLETE_NEW_PASSWORD_INIT',
    COMPLETE_NEW_PASSWORD: 'app/session/COMPLETE_NEW_PASSWORD',
    
    SOCIAL_LOG_IN  :  'app/session/SOCIAL_LOG_IN',
    SOCIAL_LOG_IN_SUCCESS  :  'app/session/SOCIAL_LOG_IN_SUCCESS',
    SOCIAL_LOG_IN_ERROR  :  'app/session/SOCIAL_LOG_IN_ERROR',
     


    ROUTER  :  '@@router/LOCATION_CHANGE',
    

    REGISTER  :  'app/session/REGISTER',
    REGISTER_SUCCESS  :  'app/session/REGISTER_SUCCESS',
    REGISTER_ERROR  :  'app/session/REGISTER_ERROR',

    RESET_LOGIN  :  'app/session/RESET_LOGIN',

    SOCIAL_LOG_IN_DATA :  'app/session/SOCIAL_LOG_IN_DATA',
    SOCIAL_LOG_IN_DATA_SUCCESS  :  'app/session/SOCIAL_LOG_IN_DATA_SUCCESS',
    SOCIAL_LOG_IN_DATA_ERROR  :  'app/session/SOCIAL_LOG_IN_DATA_ERROR',
   

};





