/*
 *
 * Upload reducer
 *
 */
import produce from 'immer';
import { upgradeConstants } from '../_constants';

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
  records: [],
  unSubscribeRecords: [],
};

/**
 * @param {object} state
 * @param {object} action
 */
const upgradeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { error, message, records } = action;
    switch (action.type) {
      case upgradeConstants.UPGRADE:
        draft.loading = true;
        draft.error = false;
        draft.message = '';
        break;
      case upgradeConstants.UPGRADE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = '';
        draft.records = records;
        break;
      case upgradeConstants.UPGRADE_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
      case upgradeConstants.USER_STATUS_GET:
        draft.loading = true;
        draft.error = false;
        draft.message = '';
        break;
      case upgradeConstants.USER_STATUS_GET_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.message = message;
        draft.records = records;
        break;
      case upgradeConstants.USER_STATUS_GET_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.records = [];
        break;
        case upgradeConstants.USER_UNSUBSCRIBE:
        draft.loading = true;
        draft.error = false;
        
        break;
      case upgradeConstants.USER_UNSUBSCRIBE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.unSubscribeRecords = records;
        break;
      case upgradeConstants.USER_UNSUBSCRIBE_ERROR:
        draft.loading = false;
        draft.error = error;
        
        break;

      default:
        break;
    }
  });
export default upgradeReducer;
