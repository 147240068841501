
import { API } from 'aws-amplify';
import { getDefaultParams } from 'helpers/tools';

function upgrade() {
  return API.post('leardon-service', '/create-checkout-session', getDefaultParams(null) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function unsubscribe(payload) {
  return API.get('leardon-service', '/stripe-unsubscribe', getDefaultParams(null, { queryStringParameters: payload }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function userStatus() {
  return API.get('leardon-service', '/check-premium-status', getDefaultParams(null) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const upgradeServices = {
upgrade,
userStatus,
unsubscribe
};

