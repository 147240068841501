import { createContext, useState } from 'react';  
import useConfig from 'hooks/useConfig';
const TableContext = createContext(null);


export const TableContextProvider = ({ children }) => {
  const { rowsPage } = useConfig();
  const tableInitialState = {
      'inbound':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'requests':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'outbound':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'clients':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'sources':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'destinations':{pageSize:rowsPage,page:0,queryParams:{},filters:{}},
      'receipt':{pageSize:rowsPage,page:0,category_type:'Receipt',queryParams:{},filters:{}},
      'receiptView':{pageSize:rowsPage,page:0,category_type:'Receipt',queryParams:{},filters:{}},
      'ingredients':{pageSize:rowsPage,page:0,category_type:'Ingredients',queryParams:{},filters:{}},
      'nutritions':{pageSize:rowsPage,page:0,category_type:'Nutritions',queryParams:{},filters:{}},
      'configReports':{pageSize:rowsPage,page:0,queryParams:{},filters:{}}
  }
  const [tableConfig,setTableConfig] = useState(tableInitialState)  
  const onChangeCalendar = (contextKey,showCalendar) => {
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],showCalendar:showCalendar,currentDate:null}}})
  };
  const onChangePagination = (contextKey,params) => {
      let {pageSize,page,filters,queryParams,globalFilterValue,rowsPage,globalAutoFocus,columnFilters,showEachFilter=false,showCalendar=false} = params
      let config = { 
          ...{[contextKey]:{
              ...tableConfig[contextKey],
              pageSize,
              page,
              filters,
              queryParams,
              globalFilterValue,
              globalAutoFocus,
              rowsPage,
              columnFilters,
              showEachFilter,
              showCalendar
          }}}  
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
      
  }
  const onChangePageSize = (contextKey,params) => {
      const {pageSize, queryParams} = params      
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],pageSize, queryParams}}})
  }
  const onChangeGlobalFilterValue = (contextKey,globalFilterValue) => { 
       let config = { 
         ...{[contextKey]:{...tableConfig[contextKey],globalFilterValue}}
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onChangeInvoiceTab = (invoiceTab) => { 
       let config = { 
         selectedInvoiceTab:invoiceTab
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onChangeColumnFilterValue = (contextKey,id,value) => { 
       let config = { 
         ...{[contextKey]:{...tableConfig[contextKey],...{["columnFilters"]:{...tableConfig[contextKey].columnFilters,[id]:value}}}}
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onResetContext = () => {
      setTableConfig({...tableInitialState})
      return tableInitialState
  };
  const onChangeCalendarDate = (contextKey,currentDate) => {
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],currentDate:currentDate}}})
  }; 
  return (
    <TableContext.Provider value={{tableConfig,onChangeCalendar,onResetContext,onChangeCalendarDate,onChangePagination,onChangePageSize,onChangeGlobalFilterValue,onChangeColumnFilterValue,onChangeInvoiceTab}}>
      {children}
    </TableContext.Provider>
  );
};

export default TableContext;
