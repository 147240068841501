import { lazy } from 'react';

// project-imports
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const SuccessPage = Loadable(lazy(() => import('pages/apps/payment/success')));

const EmptyRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),

      children: [
        {
            path: 'success',
            element: <SuccessPage/>
          }
      ]
    }
  ]
};

export default EmptyRoutes;
