import { sessionService } from '../services';
import { sessionConstants } from '../_constants';
// import { encryption } from 'helpers/tools';
// import {PASSWORD_ENCRPTION} from "config"
import { snackbarActions, snackbarClose } from 'helpers/tools';
import { Auth } from 'aws-amplify';

function login(email, password, navigate, redirect) {
  return (dispatch) => {
    dispatch(request({ email }));
    sessionService.login(email, password).then(
      (response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(newPasswordRequired(response));
          navigate('/reset', { state: { email: email } });
        } else {
          dispatch(success(response));
          setSession(response);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Incorrect username or password.') && redirect) {
          let errors = 'The verification link is no longer valid.';
          dispatch(snackbarActions(true, errors.toString()));
          navigate('/login');
        } else {
          dispatch(snackbarActions(true, error.toString()));
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      }
    );
  };

  function request(email) {
    return { type: sessionConstants.LOG_IN, email };
  }
  function success(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }
  function newPasswordRequired(user) {
    return { type: sessionConstants.NEW_PASSWORD_REQUIRED, user };
  }
  function failure(error, message) {
    return { type: sessionConstants.LOG_IN_ERROR, error, message };
  }
}

function logout() {
  return (dispatch) => {
    sessionService.logout();
    setSession(null);
    dispatch(sessionLogout());
  };
  function sessionLogout() {
    return { type: sessionConstants.LOG_OUT };
  }
}

function register(payload, navigate) {
  return (dispatch) => {
    dispatch(request());
    sessionService.register(payload).then(
      (res) => {
        const { message, error } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(res, message));
          dispatch(snackbarActions(false, message));
          if (navigate) {
            navigate('/confirm-email', { state: { email: payload.email } });
          }
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      },
      (error) => {
        console.error('Registration error:', error);
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };

  function request() {
    return { type: sessionConstants.REGISTER };
  }
  function success(user, message) {
    return { type: sessionConstants.REGISTER_SUCCESS, user, message };
  }
  function failure(error, message) {
    return { type: sessionConstants.REGISTER_ERROR, error, message };
  }
}
function loginSuccess(serviceToken) {
  return (dispatch) => {
    dispatch(success(serviceToken));
  };
  function success(serviceToken) {
    return { type: sessionConstants.LOG_IN_SUCCESS_EXIST, serviceToken };
  }
}

const setSession = (response = null, email) => {
  if (response) {
    const serviceToken = response.signInUserSession.getIdToken().getJwtToken();
    localStorage.setItem('token', serviceToken);
    localStorage.setItem('user', response.username);
    localStorage.setItem('userType', response?.attributes['custom:isPremiumUser'] === 'True' ? 'premiumUser' : 'freeUser');
    if (response?.attributes) {
      localStorage.setItem('name', response?.attributes?.name);
    } else {
      localStorage.setItem('name', response?.challengeParam?.userAttributes?.name);
    }
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
  }
};
function forgotPassword(email, navigate) {
  return (dispatch) => {
    dispatch(request());
    // dispatch(loaderActions.start());
    sessionService.forgotPassword(email).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(email));
          dispatch(snackbarActions(false, message));
          if (navigate) {
            navigate('/verify-otp', { state: { email } });
          }
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
          dispatch(failure(true, error.message));
          dispatch(snackbarActions(true, error.message));
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      }
    );
  };

  function request() {
    return { type: sessionConstants.FORGOT_PASSWORD };
  }
  function success(email) {
    return { type: sessionConstants.FORGOT_PASSWORD_SUCCESS, email };
  }
  function failure(error, message) {
    return { type: sessionConstants.FORGOT_PASSWORD_ERROR, error, message };
  }
}

function forgotPasswordSubmit(username, code, password, navigate) {
  return (dispatch) => {
    dispatch(request());
    sessionService.forgotPasswordSubmit(username.email, code, password).then(
      (res) => {
        dispatch(success(res));
        dispatch(snackbarActions(false, 'Successfully resetted'));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
        if (navigate) {
          navigate('/login');
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error, error.toString()));
      }
    );
  };

  function request() {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE };
  }
  function success(message) {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE_SUCCESS, message };
  }
  function failure(error, message) {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE_ERROR, error, message };
  }
}

function completeNewPassword(username, password, navigate) {
  return (dispatch) => {
    dispatch(request());
    sessionService.completeNewPassword(username, password).then(
      async (res) => {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        dispatch(success(currentAuthenticatedUser));
        dispatch(snackbarActions(false, 'Successfully logged in'));
        setSession(currentAuthenticatedUser);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error, error.toString()));
      }
    );
  };

  function request() {
    return { type: sessionConstants.COMPLETE_NEW_PASSWORD_INIT };
  }
  function success(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }
  function failure(error, message) {
    return { type: sessionConstants.LOG_IN_ERROR, error, message };
  }
}
function resetLogin() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: sessionConstants.RESET_LOGIN };
  }
}

function federatedSignIn(provider) {
  return async (dispatch) => {
    dispatch(request());
    try {
      await sessionService.federatedSignIn(provider);
      const user = await Auth.currentAuthenticatedUser();
      setSession(user);
      dispatch(success(user));
      dispatch(getSocialLoginData());
    } catch (error) {
      const errorMessage = error && error.message ? error.message : error.toString();
      dispatch(failure(true, errorMessage));
    }
  };

  function request() {
    return { type: sessionConstants.SOCIAL_LOG_IN };
  }
  function success(user) {
    return { type: sessionConstants.SOCIAL_LOG_IN_SUCCESS, user };
  }
  function failure(error, message) {
    return { type: sessionConstants.SOCIAL_LOG_IN_ERROR, error, message };
  }
}

function getSocialLoginData() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await sessionService.getSocialLoginData();
      dispatch(success(response));
      

      if(response.isPremiumUser==='true')
      {
        localStorage.setItem('userType', "premiumUser");
      }
      else{
        localStorage.setItem('userType', "freeUser");
      }

    } catch (error) {
      console.error('Action Error:', error);
      dispatch(failure(true, error.message));
    }
  };

  function request() {
    return { type: sessionConstants.SOCIAL_LOG_IN_DATA };
  }

  function success(response) {
   
    return { type: sessionConstants.SOCIAL_LOG_IN_DATA_SUCCESS, response };
  }

  function failure(error, message) {
    return { type: sessionConstants.SOCIAL_LOG_IN_DATA_ERROR, error, message };
  }
}

export const userActions = {
  login,
  logout,
  register,
  loginSuccess,
  forgotPassword,
  resetLogin,
  forgotPasswordSubmit,
  completeNewPassword,
  federatedSignIn,
  getSocialLoginData
};
