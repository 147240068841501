import { createContext, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { userActions} from 'store/actions';
import { useDispatch, useSelector } from 'store';
import { CognitoUser } from 'amazon-cognito-identity-js';

 
const AWSCognitoContext = createContext(null);
 
export const AWSCognitoProvider = ({ children }) => {
  const state = useSelector((state) => state.sessionReducer);
  const { isInitialized } = state;
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(() => {
    return localStorage.getItem('selectedProjectId') || '';
  });
  const updateProjectId = (newProjectId) => {
    setProjectId(newProjectId);
    localStorage.setItem('selectedProjectId', newProjectId);
  };
    useEffect(() => {
    const isRefresh = window.performance.navigation.type === 1;
 
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('token');
        if (serviceToken) {
          dispatch(userActions.loginSuccess(serviceToken));
        } else if (!isRefresh) {
          dispatch(userActions.logout());
        }
      } catch (err) {
        console.error(err);
        if (!isRefresh) {
          dispatch(userActions.logout());
        }
      }
    };
 
    init();
  }, [dispatch]);
 
  const login = async (email, password, navigate, redirect) => {
    dispatch(userActions.login(email, password, navigate, redirect));
  };
 
  
 
  const logout = () => {
    dispatch(userActions.logout());
  };
 
  const forgotPassword = async (email) => {
    const user = new CognitoUser({ Username: email });
    user.forgotPassword({
      onSuccess: function () {},
      onFailure: function () {}
    });
  };
 
  const federatedSignIn = (provider) => {
    localStorage.setItem('googleLogin', 'true');
    dispatch(userActions.federatedSignIn(provider));

                       
  };
 
  const updateProfile = () => {};
 
  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }
 
  return (
    <AWSCognitoContext.Provider value={{ ...state, login, logout, forgotPassword, federatedSignIn, updateProfile, projectId, updateProjectId }}>
      {children}
    </AWSCognitoContext.Provider>
  );
};
 
export default AWSCognitoContext;
 
