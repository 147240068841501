import { uploadService } from '../services';
import { uploadConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { loaderActions } from './loader.actions';  


function fileUpload(payload, params, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    uploadService.fileUpload(payload, params)
    .then((res)=> {
      const { response, message, error } = res;
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message));
        dispatch(loaderActions.end());
      } else {
     
        navigate('/history/receipt',{ state: { openLuckyDraw: true } })
       
        dispatch(success(response)); 
        dispatch(snackbarActions(false, 'Image uploaded successfully'));
        dispatch(loaderActions.end());
      }
    })
  }
  function request() {
    return { type: uploadConstants.GET_UPLOAD };
  }
  function success(records) {
    return { type: uploadConstants.GET_UPLOAD_SUCCESS, records };
  }
  function failure(error,message) {
    return { type: uploadConstants.GET_UPLOAD_ERROR,error,message };
  }
}

function foodEssentialsUpload(payload, params, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    uploadService.foodEssentialsUpload(payload, params)
    .then((res) => {
      const { response, message, error } = res;
      if (error) {
        dispatch(failure(true, message));
        dispatch(snackbarActions(true, message));
        dispatch(loaderActions.end());
      } else {
        const imageId = response.image_id;
        if (params.category === 'Ingredients') {
          navigate(`/history/ingredients/view/${imageId}`, { state: { data: response.Ingredients ,openLuckyDraw: true } });
        } else {
          navigate(`/history/nutritions/view/${imageId}`, { state: { data: response.Nutritions,openLuckyDraw: true  } });
        }
        dispatch(success(response));
        dispatch(snackbarActions(false, 'Image uploaded successfully'));
        dispatch(loaderActions.end());
      }
    });
  };

  function request() {
    return { type: uploadConstants.GET_UPLOAD };
  }

  function success(records) {
    return { type: uploadConstants.GET_UPLOAD_SUCCESS, records };
  }

  function failure(error, message) {
    return { type: uploadConstants.GET_UPLOAD_ERROR, error, message };
  }
}

function deleteUpload(payload) {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: uploadConstants.DELETE_UPLOAD };
  }
}

function clear() {
  return (dispatch) => {
    dispatch(request());
  }
  function request() {
    return { type: uploadConstants.CLEAR };
  }
}

export const uploadActions = {
  fileUpload,
  foodEssentialsUpload,
    deleteUpload,
    clear
};
