


import { projectConstants } from "store/_constants/project.constants";
import { projectService } from 'store/services/project.services';

function getProjectList() {
    return async (dispatch) => {
        dispatch(request());
        try {
          const response = await projectService.projectList();
          localStorage.removeItem('googleLogin')
          dispatch(success(response));
        } catch (error) {
          console.error('Action Error:', error);
          dispatch(failure(true, error.message));
        }
      };

    function request() {
        return { type: projectConstants. GET_PROJECT_LIST };
      }
    
      function success(response) {
        return { type: projectConstants. GET_PROJECT_LIST_SUCCESS, response };
      }
    
      function failure(error, message) {
        return { type: projectConstants. GET_PROJECT_LIST_ERROR, error, message };
      }
}

function federatedLoginStatus() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type:projectConstants.FEDERATED_STATUS };
  }
}
export  const projectActions = {
    getProjectList,
    federatedLoginStatus,
}