import { settingsConstants } from "store/_constants/settings.constants";
import { settingsService } from 'store/services/settings.services';
import { snackbarActions, handleSubscriptionStatus } from 'helpers/tools';
import { loaderActions } from './loader.actions';


function customizedItemTypes() {
    return async (dispatch) => {
        dispatch(request());
        try {
          const response = await settingsService.itemTypes();
        
          dispatch(success(response));
        } catch (error) {
          console.error('Action Error:', error);
          dispatch(failure(true, error.message));
        }
      };

    function request() {
        return { type: settingsConstants.ITEM_TYPE_RECORDS };
      }
    
      function success(response) {
        return { type: settingsConstants.ITEM_TYPE_RECORDS_SUCCESS, response };
      }
    
      function failure(error, message) {
        return { type: settingsConstants.ITEM_TYPE_RECORDS_ERROR, error, message };
      }
}

function addItemType(payload, navigate,state) {
  return (dispatch) => {
    dispatch(request());
    settingsService.createItemType(payload).then(
      (res) => {
        const { response, error, message } = res;
        handleSubscriptionStatus(message);
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message)); 
          dispatch(success(response));
          dispatch(customizedItemTypes());
          dispatch(loaderActions.end());
          if (state?.receiptPage === true) {
            navigate(-1);
          } 
           
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };

  function request() {
    return { type: settingsConstants.ADD_ITEM_TYPE };
  }
  function success(response) {
    return { type: settingsConstants.ADD_ITEM_TYPE_SUCCESS};
  }
  function failure(error, message) {
    return { type: settingsConstants.ADD_ITEM_TYPE_ERROR, error, message };
  }
}

function updateItemType(payload, navigate,state) {
  return (dispatch) => {
    dispatch(request());
    settingsService.UpdateItemType(payload).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message)); 
          dispatch(success(response));
          dispatch(customizedItemTypes());
          dispatch(loaderActions.end());
          if (state?.receiptPage === true) {
            navigate(-1); 
          }
      
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };

  function request() {
    return { type: settingsConstants.UPDATE_ITEM_TYPE };
  }
  function success(response) {
    return { type: settingsConstants.UPDATE_ITEM_TYPE_SUCCESS};
  }
  function failure(error, message) {
    return { type: settingsConstants.UPDATE_ITEM_TYPE_ERROR, error, message };
  }
}

function getDownloadHistory(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    settingsService.getDownloadHistory(params).then(
      (res) => {
        const { response, error, message, total } = res;

        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: settingsConstants.GET_DOWNLOAD_HISTORY};
  }
  function success(records, total, page) {
    return { type: settingsConstants.GET_DOWNLOAD_HISTORY_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: settingsConstants.GET_DOWNLOAD_HISTORY_ERROR, error, message };
  }
}

function downloadData(payload,projectId, setClearData) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    settingsService.downloadData(payload,{project_id: projectId})
    .then((res)=> {
      const { response, message, error } = res;
      handleSubscriptionStatus(message);
      if (error) {
        dispatch(failure(true))
        dispatch(snackbarActions(true, message));
        dispatch(loaderActions.end());
      } else {
        dispatch(success(response)); 
        dispatch(loaderActions.end());
        dispatch(getDownloadHistory({project_id: projectId}))
        setClearData(true)
       
      }
    })
  }
  function request() {
    return { type: settingsConstants.DOWNLOAD_RECORDS };
  }
  function success(records) {
    return { type: settingsConstants.DOWNLOAD_RECORDS_SUCCESS, records };
  }
  function failure(error,message) {
    return { type: settingsConstants.DOWNLOAD_RECORDS_ERROR, error, message };
  }
}
function addProjectName(payload)
{
return (dispatch) => {
  dispatch(request());
  settingsService.addProjectName(payload).then(
    (res) => {
      const { response, error, message } = res;
      handleSubscriptionStatus(message); 
      if (error) {
        dispatch(failure(true, message));
        dispatch(snackbarActions(true, message));
      
        
  
      } else {
        dispatch(snackbarActions(false, message));
        dispatch(success(response));
        dispatch(getProjectName());
        
      }
    },
    (error) => {
      if (error && error.message) {
        error = error.message;
      }
      dispatch(failure(true, error.toString()));
   
    }
    )}

function request() {
    return { type: settingsConstants.ADD_PROJECT_NAME };
  }

  function success(response) {
    return { type: settingsConstants.ADD_PROJECT_NAME_SUCCESS};
  }

  function failure(error, message) {
    return { type: settingsConstants.ADD_PROJECT_NAME_ERROR, error, message };
  }
}
function getProjectName()
{
return (dispatch) => {
 
  dispatch(request());
  settingsService.getProjectName().then(
    (res) => {
      const { response, error, message } = res;
      if (error) {
        dispatch(failure(true, message));
        dispatch(loaderActions.end());
      } else {
        dispatch(success(response));
        dispatch(loaderActions.end());
      
        
      }
    },
    (error) => {
      if (error && error.message) {
        error = error.message;
      }
      dispatch(failure(true, error.toString()));
      dispatch(loaderActions.end());
    }
    )}

function request() {
    return { type: settingsConstants.GET_PROJECT_LIST };
  }

  function success(response) {
    return { type: settingsConstants.GET_PROJECT_LIST_SUCCESS,response};
  }

  function failure(error, message) {
    return { type: settingsConstants.GET_PROJECT_LIST_ERROR, error, message };
  }
}
function updateProjectName(payload) {
  return (dispatch) => {
    dispatch(request());
    settingsService.updateProjectName(payload).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response));
          dispatch(loaderActions.end());
          dispatch(getProjectName());
       
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
      )}

  function request() {
    return { type: settingsConstants.UPDATE_PROJECT_NAME };
  }
  function success(record, message, error) {
    return { type: settingsConstants.UPDATE_PROJECT_NAME, record, message, error };
  }
  function failure(error, message) {
    return { type: settingsConstants.UPDATE_PROJECT_NAME, error, message };
  }
}
function deleteProjectName(payload) {

  return (dispatch) => {
    dispatch(request());
   settingsService.deleteProjectName(payload).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success( message, error));
          dispatch(getProjectName());
          dispatch(loaderActions.end());
          
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error.toString()));
      }
    );
  };
  function request() {
    return { type: settingsConstants.DELETE_PROJECT_NAME };
  }
  function success(record, message, error) {
    return { type: settingsConstants.DELETE_PROJECT_NAME_SUCCESS, message, error };
  }
  function failure(error, message) {
    return { type: settingsConstants.DELETE_PROJECT_NAME_ERROR, error, message };
  }
}


    
export  const settingsActions = {

  customizedItemTypes,
  addItemType,
  updateItemType,
  getDownloadHistory,
  downloadData,
  addProjectName,
  getProjectName,
  updateProjectName,
  deleteProjectName,
  
  };