const url = `app/settings`;

const GET_PROJECT_LIST=`${url}/GET_PROJECT_LIST`;
const GET_PROJECT_LIST_SUCCESS=`${url}/GET_PROJECT_LIST_SUCCESS`;
const GET_PROJECT_LIST_ERROR=`${url}/GET_PROJECT_LIST_ERROR`;
const FEDERATED_STATUS=`${url}/FEDERATED_STATUS`;


export const projectConstants = {
    GET_PROJECT_LIST_ERROR,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST,
    FEDERATED_STATUS
}