import { Amplify, Auth, API } from 'aws-amplify';
import { cognitoPool } from '../../aws-config/index';
import { getDefaultParams } from 'helpers/tools';
Amplify.configure({
  ...cognitoPool.auth
});

function login(username, password) {
  return Auth.signIn(username, password)
  .then(response => response)
  .catch(error => Promise.reject(error))
}

function logout(navigate) {
  Auth.signOut()
    .then(() => {
      const googleLoginKey = localStorage.getItem('googleLogin');


      localStorage.clear();
      if (googleLoginKey !== null) {
        localStorage.setItem('googleLogin', googleLoginKey);
      }
      if (navigate) {
        navigate('/login');
      }
    })
    .catch(error => {
      console.error('Error signing out:', error);
    });
}

function register(payload) {
  return API.post('no-auth', '/create-user', { body: payload })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}


function forgotPassword(email) {
  return Auth.forgotPassword(email)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function forgotPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function completeNewPassword(username, password) {
  return Auth.completeNewPassword(username,  password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function federatedSignIn(provider) {
  return Auth.federatedSignIn({provider})
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function getSocialLoginData() {
  return API.get('leardon-service','/create-user/external-signup',getDefaultParams(null))
    .then((res) => {
      return res.response;
    })
    .catch((error) => {
      console.error('API Error:', error);
      return Promise.reject(error);
    });
}


export const sessionService = {
  login,
  logout,
  register,
  forgotPassword,
  forgotPasswordSubmit,
  completeNewPassword,
  federatedSignIn,
  getSocialLoginData
};
