import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import useAuth from 'hooks/useAuth';

// types
//import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const serviceToken = window.localStorage.getItem('token');
    if (!isLoggedIn && !serviceToken) {
      navigate('login', {
        state: {
          from: '/upload'
        },
        replace: true
      });
    }
    
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
