

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
const stageEnv = process.env.REACT_APP_STAGE;
const APP_URL = process.env.REACT_APP_URL;
const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const REGION = process.env.REACT_APP_REGION;
const USER_POOL_WEBCLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;

// Define auth configurations
const devAuthConfig = {
  userPoolId: USER_POOL_ID,
  region: REGION,
  userPoolWebClientId: USER_POOL_WEBCLIENT_ID,
  oauth: {
    domain: COGNITO_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${APP_URL}/upload`,
    redirectSignOut: `${APP_URL}/login`,
    responseType: 'code'
  }
};

const demoAuthConfig = {
  userPoolId: USER_POOL_ID,
  region: REGION,
  userPoolWebClientId: USER_POOL_WEBCLIENT_ID,
  oauth: {
    domain: COGNITO_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${APP_URL}/upload`,
    redirectSignOut: `${APP_URL}/login`,
    responseType: 'code'
  }
};

let authConfig;

if (stageEnv === 'dev') {
  authConfig = { ...devAuthConfig };
} else if (stageEnv === 'demo') {
  authConfig = { ...demoAuthConfig };
} else {
  authConfig = { ...devAuthConfig };
}

// Use the consolidated authConfig for cognitoPool
export const cognitoPool = { auth: authConfig };

const dev = {
  Auth: devAuthConfig,
  API: {
    endpoints: [
      {
        name: 'no-auth',
        endpoint: API_BASE_URL ? API_BASE_URL : 'https://api.dev.smartlabelscan.com'
      },
      {
        name: 'leardon-service',
        endpoint: API_BASE_URL ? API_BASE_URL : 'https://api.dev.smartlabelscan.com'
     
      }
    ]
  },
  CDN_BASE_URL: API_BASE_URL.includes('dev') ? 'https://cdn.dev.smartlabelscan.com' : 'https://cdn.demo.smartlabelscan.com'
};

const demo = {
  Auth: demoAuthConfig,
  API: {
    endpoints: [
      {
        name: 'no-auth',
        endpoint: API_BASE_URL ? API_BASE_URL : 'https://api.demo.smartlabelscan.com'
      },
      {
        name: 'leardon-service',
        endpoint: API_BASE_URL ? API_BASE_URL : 'https://api.demo.smartlabelscan.com'
      
      }
    ]
  }
};

let config;

if (stageEnv === 'demo') {
  config = { ...demo };
} else if (stageEnv === 'dev') {
  config = { ...dev };
} else {
  config = { ...dev };
}

export default config;
