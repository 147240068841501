// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

import uploadIcon   from "assets/icons/upload_icon.svg"
import dashboardIcon from "assets/icons/dashboard_icon.svg"


// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = { 
  type: 'group',
  divider:true,
  children: [
    {
      id: 'dashboard',
      parent: '',
      icon: dashboardIcon,
      iconType:'img',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard'
    },
    {
      id: 'upload',
      parent: '',
      icon: uploadIcon,
      iconType:'img',
      title: <FormattedMessage id="upload" />,
      type: 'item',
      url: '/upload'
    },
    {
      id: 'history',
      parent: '',
      title: <FormattedMessage id="history" />,
      type: 'collapse',
      iconType:'img',
      children: [
        {
          id: 'receipt',
          parent: 'history',
          title: <FormattedMessage id="receipt" />,
          type: 'item',
          url: '/history/receipt'
        },
        {
          id: 'ingredients',
          parent: 'history',
          title: <FormattedMessage id="ingredients" />,
          type: 'item',
          url: '/history/ingredients'
        },
        {
          id: 'nutritional-values',
          parent: 'stock',
          title: <FormattedMessage id="nutritional-values" />,
          type: 'item',
          url: '/history/nutritions'
        }
      ]
    }, {
      id: 'settings',
      parent: '',
      title: <FormattedMessage id="settings" />,
      type: 'collapse',
      iconType:'img',
      children: [
        {
          id: 'manage-projects',
          parent: 'settings',
          title: <FormattedMessage id="manage-projects" />,
          type: 'item',
          url: '/settings/manage-projects'
        },
        {
          id: 'customized-item-types',
          parent: 'settings',
          title: <FormattedMessage id="customized-item-types" />,
          type: 'item',
          url: '/settings/customizeditemtypes'
        },
        {
          id: 'config-reports',
          parent: 'settings',
          title: <FormattedMessage id="config-reports" />,
          type: 'item',
          url: '/settings/config-reports'
        },
        {
          id: 'plans',
          parent: 'settings',
          title: <FormattedMessage id="plans" />,
          type: 'item',
          url: '/settings/plans'
        },
      ]
    }
  ]
};

export default applications;
