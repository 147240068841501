import produce from 'immer';

import { projectConstants } from '../_constants';

const initialState = {
 
  projectList: [],
  projectLoading: false,
  error: false,
  success: false,
  message: null,
  googleSignIn: false,
  googleLogInData:true,
};

export default function projectReducer(state = initialState, props) {
  const { type, response, error, message,  } = props;
  const googleSignInKey = localStorage.getItem('googleLogin');


  return produce(state, (draft) => {
    switch (type) {
      case projectConstants.GET_PROJECT_LIST:
        draft.error = false;
        draft.projectLoading= true;
        draft.success = false;
        draft.message = '';
        break;
      case projectConstants.GET_PROJECT_LIST_SUCCESS:
        draft.projectList = response;
        draft.projectLoading = false;
        draft.error = false;
        draft.googleLogInData=false;
        break;
      case projectConstants.GET_PROJECT_LIST_ERROR:
        draft.projectLoading= false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.googleSignIn = googleSignInKey ? true : false;
        draft.googleLogInData=false;
        break;
        case projectConstants.FEDERATED_STATUS:
        draft.projectLoading= true;
        draft.googleSignIn =false;
        draft.googleLogInData=false;
        break;
          
        
        default:
   
        break;
}
});
}
