import { API } from 'aws-amplify';
import { getDefaultParams } from 'helpers/tools';

function fileUpload(payload, queryParams) {
  return API.post('leardon-service', '/image', getDefaultParams( {body: payload, queryStringParameters: queryParams}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function foodEssentialsUpload(payload, queryParams) {
  return API.post('leardon-service', '/food-essentials', getDefaultParams( {body: payload, queryStringParameters: queryParams}) )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const uploadService = {
  fileUpload,
  foodEssentialsUpload
};
