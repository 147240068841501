import { Stack, Typography, Avatar, Tooltip } from '@mui/material';
import LogoutIcon from 'assets/icons/logout.svg';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'store';
import { userActions } from 'store/actions';

const LogoutContent = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userActions.logout());
  };

  const maxLength = 22;
  const userEmail = localStorage.getItem('user');
  const name = localStorage.getItem('name');

  const renderTooltip = (text) => {
    if (text && text.length > maxLength) {
      const truncatedText = text.substring(0, maxLength) + '...';

      return (
        <Tooltip title={text} arrow style={{ cursor: 'pointer' }}>
          <Typography variant="body2" color="secondary">
            {truncatedText}
          </Typography>
        </Tooltip>
      );
    } else {
      return text;
    }
  };

  if (name == null) {
    async function storeData() {
      try {
        const res = await Auth.currentAuthenticatedUser();

        const ownerEmail = res.signInUserSession.idToken.payload.email;

        const [namePart] = ownerEmail.split('@');

        const nameWithoutNumbers = namePart.replace(/[0-9]/g, '');

        const formattedName = nameWithoutNumbers.charAt(0).toUpperCase() + nameWithoutNumbers.slice(1);

        localStorage.setItem('name', formattedName);
      } catch (err) {
        console.log(err);
      }
    }
    storeData();
  }

  const truncatedUserEmail = renderTooltip(userEmail);
  const truncatedUserName = renderTooltip(name?.charAt(0).toUpperCase() + name?.slice(1));

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Avatar alt="profile user" src={''} variant="circular" />
      <Stack spacing={0.5}>
        <Typography variant="subtitle2" sx={{ color: '#111A2C' }}>
          {truncatedUserName}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#111A2C' }}>
          {truncatedUserEmail}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#111A2C' }}></Typography>
      </Stack>
      <img src={LogoutIcon} onClick={logOut} width={20} style={{ cursor: 'pointer' }} />
    </Stack>
  );
};

export default LogoutContent;
