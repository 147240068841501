/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/history`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORDS`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const SEARCH_RECORDS = `${url}/SEARCH_RECORDS`;
const SEARCH_RECORDS_SUCCESS = `${url}/SEARCH_RECORDS_SUCCESS`;
const SEARCH_RECORDS_ERROR = `${url}/SEARCH_RECORDS_ERROR`;
const VIEW_RECORDS = `${url}/VIEW_RECORDS`;
const VIEW_RECORDS_SUCCESS = `${url}/VIEW_RECORDS_SUCCESS`;
const VIEW_RECORDS_ERROR = `${url}/VIEW_RECORDS_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const ITEM_TYPE_RECORDS = `${url}/ITEM_TYPE_RECORDS`;
const ITEM_TYPE_RECORDS_SUCCESS = `${url}/ITEM_TYPE_RECORDS_SUCCESS`;
const ITEM_TYPE_RECORDS_ERROR = `${url}/ITEM_TYPE_RECORDS_ERROR`;
const RATING_RECORDS=`${url}/RATING_RECORDS`;
const RATING_RECORDS_SUCCESS=`${url}/RATING_RECORDS_SUCCESS`;
const RATING_RECORDS_ERROR=`${url}/RATING_RECORDS_ERROR`;



export const historyConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  SEARCH_RECORDS,
  SEARCH_RECORDS_SUCCESS,
  SEARCH_RECORDS_ERROR,
  VIEW_RECORDS,
  VIEW_RECORDS_SUCCESS,
  VIEW_RECORDS_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  ITEM_TYPE_RECORDS_ERROR,
  ITEM_TYPE_RECORDS_SUCCESS,
  ITEM_TYPE_RECORDS,
  RATING_RECORDS,
  RATING_RECORDS_SUCCESS,
  RATING_RECORDS_ERROR
};
