import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { saveFireBaseToken, updateFireBaseToken, triggerNotify } from 'store/actions/notification.actions';
import useAuth from 'hooks/useAuth';


const FirebaseComponent = () => {
    const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const messagingRef = useRef(null);





    const getOrRegisterServiceWorker = () => {
        if ('serviceWorker' in navigator) {
            return navigator.serviceWorker
                .getRegistration('/firebase-push-notification-scope')
                .then((serviceWorker) => {
                    if (serviceWorker) return serviceWorker;
                    return navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                        scope: '/firebase-push-notification-scope',
                    });
                });
        }
        throw new Error('The browser doesn\'t support service workers.');
    };

    const getFirebaseToken = async (messaging) => {
        try {
            const serviceWorkerRegistration = await getOrRegisterServiceWorker();
            const newToken = await getToken(messaging, { serviceWorkerRegistration });

            const storedToken = localStorage.getItem('firebaseToken');
            if (storedToken && storedToken !== newToken) {
                dispatch(updateFireBaseToken(storedToken, newToken));
            } else if (!storedToken) {
                dispatch(saveFireBaseToken(newToken));
            }
            localStorage.setItem('firebaseToken', newToken);

        } catch (err) {
            console.error('An error occurred while retrieving firebase token.', err);
        }
    };

    const notify = (message) => {
        let parsedMsg = '';    
        if (message) {
            if (typeof message === 'string') {
                parsedMsg = message.replace(/True/g, 'true').replace(/False/g, 'false');
                try {
                    const parsedObject = JSON.parse(parsedMsg);
                    dispatch(triggerNotify(parsedObject));
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else {
                console.error('Message is not a string:', message);
            }
        } else {
            console.error('Message is empty or undefined.');
        }
    };

    useEffect(() => {
        if (!isLoggedIn) return;

        if (!messagingRef.current) {
            const firebaseConfig = {
                apiKey: "AIzaSyDpuycGS3DtzQZDfgqw3rh1aBTX6Acxn04",
                authDomain: "smartlabelscan-4eafb.firebaseapp.com",
                projectId: "smartlabelscan-4eafb",
                storageBucket: "smartlabelscan-4eafb.appspot.com",
                messagingSenderId: "930354263085",
                appId: "1:930354263085:web:df74f8ac0f1fe24359f4a4",
                measurementId: "G-18XGB7VSNN"
              };              

            const firebaseApp = initializeApp(firebaseConfig);
            messagingRef.current = getMessaging(firebaseApp);
        }

        (async () => {
            await getFirebaseToken(messagingRef.current);
        })();

        onMessage(messagingRef.current, (payload) => {
            console.log('Foreground message received:', payload);
            const { notification } = payload;
            notify(notification.body);
        });

        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'FIREBASE_BACKGROUND_MESSAGE') {
                console.log('Background message received:', event.data.payload);
                const { notification } = event.data.payload;
                notify(notification.body);
            }
        });

        setInterval(async () => {
            const newToken = await getToken(messagingRef.current);
            const storedToken = localStorage.getItem('firebaseToken');
            if (newToken && storedToken !== newToken) {
                console.log('New FCM Token:', newToken);
                await dispatch(updateFireBaseToken(storedToken, newToken));
                localStorage.setItem('firebaseToken', newToken);
            }
        }, 60 * 60 * 1000);

    }, [isLoggedIn]);

    return <></>;
};

export default FirebaseComponent;
