
import { rewardConstants } from "store/_constants/reward.constants";
import { rewardService } from 'store/services/reward.services';

function getRewardRecords() {
    return async (dispatch) => {
        dispatch(request());
        try {
          const response = await rewardService.rewardRecords();
        
          dispatch(success(response));
        } catch (error) {
          console.error('Action Error:', error);
          dispatch(failure(true, error.message));
        }
      };

    function request() {
        return { type: rewardConstants.GET_REWARD_RECORD };
      }
    
      function success(response) {
        return { type: rewardConstants.GET_REWARD_RECORD_SUCCESS, response };
      }
    
      function failure(error, message) {
        return { type: rewardConstants.GET_REWARD_RECORD_ERROR, error, message };
      }
}
export  const rewardActions = {
    getRewardRecords,  
}