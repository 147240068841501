/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { historyConstants } from '../_constants';

const initialState = {
  rating: {},
  records: [],
  record: {},
  storeTypes: [],
  itemTypes: [],
  loading: false,
  error: false,
  success: false,
  message: null,
  pageSize: 5,
  page: 0,
  total: null,
  filters: null,
  deleteSuccess: false,
  ratingLoading: false
};

export default function Reducer(state = initialState, props) {
  const { rating, type, records, record, error, message, page, total, storeTypes, itemTypes } = props;

  return produce(state, (draft) => {
    switch (type) {
      case historyConstants.LOAD_RECORDS:
      case historyConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case historyConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = [];
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = Array.isArray(allRecords) ? allRecords.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i) : [];
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        break;
      case historyConstants.LOAD_RECORD_SUCCESS:
        draft.records = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        break;

      case historyConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case historyConstants.SEARCH_RECORDS:
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case historyConstants.SEARCH_RECORDS_SUCCESS:
        draft.storeTypes = storeTypes;
        draft.loading = false;
        draft.error = false;
        break;
      case historyConstants.SEARCH_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case historyConstants.ITEM_TYPE_RECORDS:
        draft.error = false;
        draft.loading = true;
        draft.success = false;
        draft.message = '';
        break;
      case historyConstants.ITEM_TYPE_RECORDS_SUCCESS:
        draft.itemTypes = itemTypes;
        draft.loading = false;
        draft.error = false;
        break;
      case historyConstants.ITEM_TYPE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case historyConstants.VIEW_RECORDS_SUCCESS:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.records = records;
        break;
      case historyConstants.VIEW_RECORDS:
        draft.loading = true;
        break;
      case historyConstants.DELETE_RECORD:
          draft.loading = true;
          draft.updateError = null;
          draft.success = false;
          draft.error = false;
          draft.updateSuccess = false;
          draft.message = null;
          break;
      case historyConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.id.toString() !== record.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case historyConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.error = true;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case historyConstants.UPDATE_RECORD:
        draft.loading = true;
        draft.updateError = null;
        draft.success = false;
        draft.error = false;
        draft.updateSuccess = false;
        draft.message = null;
        break;
      case historyConstants.UPDATE_RECORD_SUCCESS:
        draft.loading = false;
        draft.updateError = null;
        draft.success = true;
        draft.error = false;
        draft.updateSuccess = true;
        draft.message = message;
        break;
      case historyConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.error = true;
        draft.updateSuccess = false;
        draft.message = message;
        break;

      case historyConstants.RATING_RECORDS:
        draft.error = false;
        draft.ratingLoading = true;
        draft.success = false;
        draft.message = '';
        break;
      case historyConstants.RATING_RECORDS_SUCCESS:
        draft.rating = rating;
        draft.ratingLoading = false;
        draft.error = false;
        break;
      case historyConstants.RATING_RECORDS_ERROR:
        draft.ratingLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;

      default:
        break;
    }
  });
}
