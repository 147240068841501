import { dashboardService } from 'store/services';
import { dashboardConstants } from 'store/_constants';
import { snackbarActions, handleSubscriptionStatus  } from 'helpers/tools';
import { loaderActions } from './loader.actions';

function loadDashboardRecords(start_date, end_date,project_id) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    dashboardService.loadDashboardRecords(start_date, end_date,project_id).then(
      (res) => {
        const { response, error, message } = res; 
        handleSubscriptionStatus(message);
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: dashboardConstants.LOAD_DASHBOARD_RECORDS };
  }
  function success(records) {
    return { type: dashboardConstants.LOAD_DASHBOARD_RECORDS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: dashboardConstants.LOAD_DASHBOARD_RECORDS_ERROR, error, message };
  }
}

function loadBarChart(start_date, end_date,project_id) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    dashboardService.loadBarChart(start_date, end_date,project_id).then(
      (res) => {
        const { response, error, message } = res;
        handleSubscriptionStatus(message);
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: dashboardConstants.LOAD_BAR_CHART_RECORDS };
  }
  function success(records) {
    return { type: dashboardConstants.LOAD_BAR_CHART_RECORDS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: dashboardConstants.LOAD_BAR_CHART_RECORDS_ERROR, error, message };
  }
}

function clear() {
  return { type: dashboardConstants.CLEAR };
}

export const dashboardActions = {
  loadDashboardRecords,
  loadBarChart,
  clear
};
