
const url = `app/settings`;

const ITEM_TYPE_RECORDS = `${url}/ITEM_TYPE_RECORDS`;
const ITEM_TYPE_RECORDS_SUCCESS = `${url}/ITEM_TYPE_RECORDS_SUCCESS`;
const ITEM_TYPE_RECORDS_ERROR = `${url}/ITEM_TYPE_RECORDS_ERROR`;

const ADD_ITEM_TYPE = `${url}/ADD_ITEM_TYPE`;
const ADD_ITEM_TYPE_SUCCESS = `${url}/ADD_ITEM_TYPE_SUCCESS`;
const ADD_ITEM_TYPE_ERROR = `${url}/ADD_ITEM_TYPE_ERROR`;

const UPDATE_ITEM_TYPE = `${url}/UPDATE_ITEM_TYPE`;
const UPDATE_ITEM_TYPE_SUCCESS = `${url}/UPDATE_ITEM_TYPE_SUCCESS`;
const UPDATE_ITEM_TYPE_ERROR = `${url}/UPDATE_ITEM_TYPE_ERROR`;

const GET_DOWNLOAD_HISTORY = `${url}/GET_DOWNLOAD_HISTORY`;
const GET_DOWNLOAD_HISTORY_SUCCESS = `${url}/GET_DOWNLOAD_HISTORY_SUCCESS`;
const GET_DOWNLOAD_HISTORY_ERROR = `${url}/GET_DOWNLOAD_HISTORY_ERROR`;

const DOWNLOAD_RECORDS = `${url}/DOWNLOAD_RECORDS`;
const DOWNLOAD_RECORDS_SUCCESS = `${url}/DOWNLOAD_RECORDS_SUCCESS`;
const DOWNLOAD_RECORDS_ERROR = `${url}/DOWNLOAD_RECORDS_ERROR`;

const ADD_PROJECT_NAME = `${url}/ADD_PROJECT_NAME`;
const ADD_PROJECT_NAME_SUCCESS = `${url}/ADD_PROJECT_NAME_SUCCESS`;
const ADD_PROJECT_NAME_ERROR = `${url}/ADD_PROJECT_NAME_ERROR`;

const GET_PROJECT_NAME = `${url}/GET_PROJECT_NAME`;
const GET_PROJECT_NAME_SUCCESS = `${url}/GET_PROJECT_NAME_SUCCESS`;
const GET_PROJECT_NAME_ERROR = `${url}/GET_PROJECT_NAME_ERROR`;

const UPDATE_PROJECT_NAME = `${url}/UPDATE_PROJECT_NAME`;
const UPDATE_PROJECT_NAME_SUCCESS = `${url}/UPDATE_PROJECT_NAME_SUCCESS`;
const UPDATE_PROJECT_NAME_ERROR = `${url}/UPDATE_PROJECT_NAME_ERROR`;

const DELETE_PROJECT_NAME = `${url}/DELETE_PROJECT_NAME`;
const DELETE_PROJECT_NAME_SUCCESS = `${url}/DELETE_PROJECT_NAME_SUCCESS`;
const DELETE_PROJECT_NAME_ERROR = `${url}/DELETE_PROJECT_NAME_ERROR`;


const GET_PROJECT_LIST = `${url}/GET_PROJECT_LIST`;
const GET_PROJECT_LIST_SUCCESS = `${url}/GET_PROJECT_LIST_SUCCESS`;
const GET_PROJECT_LIST_ERROR = `${url}/GET_PROJECT_LIST_ERROR`;




export const settingsConstants = {
    ITEM_TYPE_RECORDS_ERROR,
    ITEM_TYPE_RECORDS_SUCCESS,
    ITEM_TYPE_RECORDS,
    ADD_ITEM_TYPE,                 
    ADD_ITEM_TYPE_SUCCESS,       
    ADD_ITEM_TYPE_ERROR,
    UPDATE_ITEM_TYPE,                 
    UPDATE_ITEM_TYPE_SUCCESS,       
    UPDATE_ITEM_TYPE_ERROR,
    GET_DOWNLOAD_HISTORY,
    GET_DOWNLOAD_HISTORY_SUCCESS,
    GET_DOWNLOAD_HISTORY_ERROR,
    DOWNLOAD_RECORDS,
    DOWNLOAD_RECORDS_SUCCESS,
    DOWNLOAD_RECORDS_ERROR,
    ADD_PROJECT_NAME,
    ADD_PROJECT_NAME_SUCCESS,
    ADD_PROJECT_NAME_ERROR,
    GET_PROJECT_NAME,
    GET_PROJECT_NAME_SUCCESS,
    GET_PROJECT_NAME_ERROR,
    UPDATE_PROJECT_NAME,
    UPDATE_PROJECT_NAME_SUCCESS,
    UPDATE_PROJECT_NAME_ERROR,
    DELETE_PROJECT_NAME,
    DELETE_PROJECT_NAME_SUCCESS,
    DELETE_PROJECT_NAME_ERROR,
    GET_PROJECT_LIST,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_ERROR,
  

};