import { createSlice } from '@reduxjs/toolkit';
import { notificationAPI } from '../services';
import { notificationConstants } from '../_constants';

const initialState = {
    actionRequired: false,
    saveStatus: false,
    error: null,
};

const notificationPreferences = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        triggerNotify(state, { payload }) {            
            state.actionRequired = payload.actionRequired;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(notificationConstants.SAVE_FIREBASE_TOKEN, (state) => {
                state.saveStatus = true;
            })
            .addCase(notificationConstants.SAVE_FIREBASE_TOKEN_SUCCESS, (state) => {
                state.saveStatus = false;
            })
            .addCase(notificationConstants.SAVE_FIREBASE_TOKEN_ERROR, (state, action) => {
                state.saveStatus = false;
                state.error = action.error.message;
            })
            .addCase(notificationConstants.UPDATE_FIREBASE_TOKEN, (state) => {
                state.saveStatus = true;
            })
            .addCase(notificationConstants.UPDATE_FIREBASE_TOKEN_SUCCESS, (state) => {
                state.saveStatus = false;
            })
            .addCase(notificationConstants.UPDATE_FIREBASE_TOKEN_ERROR, (state, action) => {
                state.saveStatus = false;
                state.error = action.error.message;
            });
    },
});

export const {
    triggerNotify,
} = notificationPreferences.actions;

export const saveFireBaseToken = (fireBaseToken) => async (dispatch) => {
    const payload = {
        UserFcmTokens: fireBaseToken,
    };
    dispatch({ type: notificationConstants.SAVE_FIREBASE_TOKEN });
    try {
        await notificationAPI.saveFireBaseToken(payload);
        dispatch({ type: notificationConstants.SAVE_FIREBASE_TOKEN_SUCCESS });
    } catch (error) {
        console.log(error);
        dispatch({ type: notificationConstants.SAVE_FIREBASE_TOKEN_ERROR, error });
    }
};

export const updateFireBaseToken = (oldToken, newToken) => async (dispatch) => {
    const payload = {
        oldfcmtoken: oldToken,
        newfcmtoken: newToken,
    };
    dispatch({ type: notificationConstants.UPDATE_FIREBASE_TOKEN });
    try {
        await notificationAPI.updateFireBaseToken(payload);
        dispatch({ type: notificationConstants.UPDATE_FIREBASE_TOKEN_SUCCESS });
    } catch (error) {
        console.log(error);
        dispatch({ type: notificationConstants.UPDATE_FIREBASE_TOKEN_ERROR, error });
    }
};

export default notificationPreferences.reducer;
