
const key = 'app/notifications'
export const notificationConstants = {    

    SAVE_FIREBASE_TOKEN: `${key}/SAVE_FIREBASE_TOKEN`,
    SAVE_FIREBASE_TOKEN_SUCCESS: `${key}/SAVE_FIREBASE_TOKEN_SUCCESS`,
    SAVE_FIREBASE_TOKEN_ERROR: `${key}/SAVE_FIREBASE_TOKEN_ERROR`,

    UPDATE_FIREBASE_TOKEN: `${key}/UPDATE_FIREBASE_TOKEN`,
    UPDATE_FIREBASE_TOKEN_SUCCESS: `${key}/UPDATE_FIREBASE_TOKEN_SUCCESS`,
    UPDATE_FIREBASE_TOKEN_ERROR: `${key}/UPDATE_FIREBASE_TOKEN_ERROR`,
    };