import { upgradeServices } from '../services';
import { upgradeConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { loaderActions } from './loader.actions';

function upgrade() {
  return (dispatch) => {
    // Dispatch loader start action
    dispatch(loaderActions.start());
    // Dispatch upgrade request action
    dispatch(request());

    upgradeServices.upgrade().then((res) => {
      const { response, message, error } = res;

      if (error) {
        dispatch(failure(true, message));
        dispatch(snackbarActions(true, message));
        dispatch(loaderActions.end());
      } else {
        dispatch(success(response));

        if (response.checkout_url) {
          window.location.href = response.checkout_url;
        }

        dispatch(loaderActions.end());
      }
    });
  };

  function request() {
    return { type: upgradeConstants.UPGRADE };
  }

  function success(records) {
    return { type: upgradeConstants.UPGRADE_SUCCESS, records };
  }

  function failure(error, message) {
    return { type: upgradeConstants.UPGRADE_ERROR, error, message };
  }
}

  function unsubscribe(payload) {
    return (dispatch) => {
      dispatch(loaderActions.start());
      dispatch(request());
      upgradeServices.unsubscribe(payload)
      .then((res)=> {
        const { response, message, error } = res;
        if (error) {
          dispatch(failure(true, message))
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message)); 
          dispatch(loaderActions.end());
        }
      })
    }
    function request() {
      return { type: upgradeConstants.USER_UNSUBSCRIBE };
    }
    function success( records, message) {
      return { type: upgradeConstants.USER_UNSUBSCRIBE_SUCCESS, records, message };
    }
    function failure(error,message) {
      return { type: upgradeConstants.USER_UNSUBSCRIBE_ERROR,error,message };
    }
  }

  function userStatus() {
    return (dispatch) => {
      dispatch(loaderActions.start());
      dispatch(request());
      upgradeServices.userStatus()
      .then((res)=> {
        const { response, message, error } = res;
        if (error) {
          dispatch(failure(true, message))
          // dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message)); 
          dispatch(loaderActions.end());
         if(response.is_premium === true)
         {
          localStorage.setItem('userType','premiumUser')
         }
         else{
          localStorage.setItem('userType','freeUser')
         }
         
        }
      })
    }
    function request() {
      return { type: upgradeConstants.USER_STATUS_GET };
    }
    function success(records, message) {
      return { type: upgradeConstants.USER_STATUS_GET_SUCCESS, records, message };
    }
    function failure(error,message) {
      return { type: upgradeConstants.USER_STATUS_GET_ERROR,error,message };
    }
  }

  export const upgradeActions = {
    upgrade,
    userStatus,
    unsubscribe
  }
  
