// types
import { PaletteThemeProps } from 'types/theme';
import { ThemeMode } from 'types/config';
 
// ==============================|| PRESET THEME - DEFAULT ||============================== //
 
const Default = (mode: ThemeMode): PaletteThemeProps => {
  const contrastText = '#fff';
  let primaryColors = ['#E9F0FF', '#C8D9FF', '#A3C0FF', '#7EA6FF', '#6293FF', '#3A73AA' ,'#4680FF', '#3F78FF', '#376DFF', '#2F63FF', '#2050FF','#DFE3E7','#111A2C'];
  let secondaryColors = ['#F8F9FA', '#F8F9FA', '#F3F5F7', '#DBE0E5', '#BEC8D0', '#8996A4', '#5B6B79', '#3E4853', '#1D2630', '#131920',"#FFFFFF",'#5C667A',"#6E767D"];
  let errorColors = ['#f5bebe', '#e76767', '#dc2626', '#d31c1c', '#c50d0d','#F36458','#FDF3F2'];
  let warningColors = ['#f7dcb3', '#edad4d', '#e58a00', '#de7700', '#d35a00'];
  let infoColors = ['#c5eff3', '#78d9e2', '#3ec9d6', '#30bccc', '#1ba9bc','#F9FAFB','#E4E2E5', '#3D413E','#477EB3'];
  let successColors = ['#c0e5d9', '#6bc2a5', '#2ca87f', '#21976c', '#107d4f', '#2AA261','#2AA2613D','#2AA2610F','#477EB33D','#477EB30F','#0000001A','#217447','#F1FCF6', '#5BCD8F'];
 
  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      300: primaryColors[10],
      500: primaryColors[11],
      600: primaryColors[12],
      contrastText
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      light: secondaryColors[3],
      400: secondaryColors[4],
      500: secondaryColors[5]!,
      main: secondaryColors[6],
      dark: secondaryColors[7],
      800: secondaryColors[8],
      darker: secondaryColors[9],
      600: secondaryColors[10],
      300: secondaryColors[11],
      700: secondaryColors[12],
      contrastText
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      100: errorColors[5],
      200: errorColors[6],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      100: infoColors[5],
      200: infoColors[6],
      300: infoColors[7],
      400: infoColors[8],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      100: successColors[5],
      200: successColors[6],
      300: successColors[7],
      400: successColors[8],
      500: successColors[9],
      600: successColors[10],
      700: successColors[11],
      800: successColors[12],
      900: successColors[13],
      contrastText
    }
  };
};
 
export default Default;
