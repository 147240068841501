import { getDefaultParams} from '../../helpers/tools';
import { Amplify, API } from 'aws-amplify';
import config from '../../config';

Amplify.configure({
  ...config.Auth
});

function saveFireBaseToken(payload) {
    return API.post('leardon-service', '/upload-fcm-token', getDefaultParams({ body: payload }))
      .then(response => response)
      .catch(error => Promise.reject(error))
  }
  
  function updateFireBaseToken(payload) {
    return API.put('leardon-service', '/update-fcm-token', getDefaultParams({ body: payload }))
      .then(response => response)
      .catch(error => Promise.reject(error))
  }

  export const notificationAPI = {
    saveFireBaseToken,
    updateFireBaseToken
  };