import { getDefaultParams } from 'helpers/tools';
import { API } from 'aws-amplify';
function loadDashboardRecords(start_date, end_date,project_id) {
  return API.get('leardon-service', '/data', getDefaultParams(null, { queryStringParameters: start_date, end_date ,project_id}))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadBarChart(start_date, end_date,project_id) {
  return API.get('leardon-service', '/data/bar-filters', getDefaultParams(null, { queryStringParameters: start_date, end_date,project_id}))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export const dashboardService = {
  loadDashboardRecords,
  loadBarChart
};
