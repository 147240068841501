// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
// import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar'; 
import sessionReducer from './session.reducers';
//import invoice from './invoice'; 
import alert from './alert.reducers';
import dialog from './dialog.reducers';
import loader from './loader.reducers';
import upload from './upload.reducers';
import count from './counts.reducers';
import history from './history.reducers';
import dashboard from './dashboard.reducers';
import notifications from '../actions/notification.actions';
import settings from './settings.reducers';
import upgradeReducer from './upgrade.reducers';
import project from './project.reducers';
import reward from './reward.reducers';

const reducers = combineReducers({
  menu,
  snackbar,
  sessionReducer, 
  alert, 
  dialog,
  loader, 
  upload,
  history,
  dashboard,
  settings,
  upgradeReducer,
  project,
  reward,
  notifications: persistReducer( {key: 'notifications', storage, whitelist: ['actionRequired']}, notifications),
  count: persistReducer(
    {
      key: 'counter',
      storage
    },
    count
  ),
});

export default reducers;
