const key = 'app/upgrade'
export const upgradeConstants = {    
    UPGRADE: `${key}/UPGRADE`,
    UPGRADE_SUCCESS: `${key}/UPGRADE_SUCCESS`,
    UPGRADE_ERROR: `${key}/UPGRADE_ERROR`,

    USER_STATUS_GET: `${key}/USER_STATUS_GET`,
    USER_STATUS_GET_SUCCESS: `${key}/USER_STATUS_GET_SUCCESS`,
    USER_STATUS_GET_ERROR: `${key}/USER_STATUS_GET_ERROR`,

    USER_UNSUBSCRIBE: `${key}/USER_UNSUBSCRIBE`,
    USER_UNSUBSCRIBE_SUCCESS: `${key}/USER_UNSUBSCRIBE_SUCCESS`,
    USER_UNSUBSCRIBE_ERROR: `${key}/USER_UNSUBSCRIBE_ERROR`,
    };